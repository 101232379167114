export const OUTPUT_FORMAT_EXPORT = ['xls', 'pdf', 'txt', 'csv', 'csv', 'custom'];

export const FILENAME_REPORT = {
	EXPORT_EXPENSE: 'Expense Export',
	EXPORT_PO: 'Purchase Order Export',
	EXPORT_TS: 'Timesheet Export',
	EXPORT_USER: 'User Export',
	REPORT_EXPENSE: 'Expense Report',
	REPORT_PO: 'Purchase Order Report',
	REPORT_TS: 'Timesheet Report',
	REPORT_USER: 'Infosheet Report',
	REPORT_EXPENSE_ITEM: 'Expense Item Report'
};

export const OODRIVE_RETURN_PAGE_TYPE = {
	SUCCESS: 'success',
	FAILURE: 'failure'
};

export const ACTION = {
	ADD: 'ADD',
	UPDATE: 'UPDATE'
};

export const FLAG_TYPE = {
	TAX: 0,
	DEFAULT_FLAG: 1
};

export const INPUT_TYPE = {
	TEXT: 0,
	INTEGER: 1,
	FLOAT: 2
};

export const SUPPLIER = {
	STANDARD: 0,
	ACCOMODATION: 1,
	ELECTRICITY: 2,
	LOCATION: 3,
	OWNER: 4,
	LICENSE_OLDER: 5,
	CLIENT: 6,
	TENANT: 7,
	GREEN: 8,
	LEGAL_ENTITY: 9,
	ARCHITECT: 10,
	SYNDIC: 11,
	OFFER: 12,
	GAS: 13,
	WATER: 14
};

export const EXPENSE_TYPE = {
	TICKET: 0,
	INVOICE: 1,
	TRAVEL: 2
};

export const EXPENSE_NAME = ['Ticket', 'Invoice', 'Travel'];

export const ERROR_STRING = {
	ITEM_NOT_FOUND: 'Item not found'
};

export const TRANSPORTATION_MEAN_MENU = {
	BOAT: 1404,
	BUS: 1405,
	CAR: 1406,
	FREIGHT: 1418,
	OTHER: 1407
};

export const TRANSPORTATION_MEAN_DETAIL_MENU = {
	CAR: 1408,
	PLANE: 1419,
	SCOOTER: 1420,
	TRAIN: 1421,
	VAN: 1422,
	PLANE_TYPE: 1423,
	HIRE_RENTAL: 1424
};

export const MEAL_TYPE_MENU = 1426;

export const ENERGY_TYPE_MENU = 1425;

export const WASTE_STANDARD_TYPE_MENU = 1427;

export const WASTE_DESTRUCTION_TYPE_MENU = 1428;

export const REASON_ENERGY_TYPE_MENU = 1429;

export const ENERGY_TYPE_FOR_LOCATION_PROMISE = 1430;

export const CAR_LODGE_ENERGY_TYPE_MENU = 1431;

export const CARBON_VERIFIED_STATUS_MENU = 1433;

export const CARBON_PRODUCTION_LOGISTIC_TYPE = 1435;

export const INIT_DATA_CARBON = {
	strDate: new Date(),
	endDate: new Date(),
	description: '',
	amount: 0,
	entityType: 8,
	coTwoCategory: 0,
	entityId: 0,
	type: 1,
	subType: 0,
	additionalSubType: 0,
	paidByProduction: false,
	verified: 0,
	km: 0,
	liter: 0,
	mcube: 0,
	kwh: 0,
	msquare: 0,
	number: 0,
	kg: 0,
	waterVolumeUsed: 0,
	supplierId: 0,
	boolValue: false,
	mealRed: 0,
	mealWhite: 0,
	mealVege: 0,
	mealNotSpecified: 0,
	numberOfNight: 0,
	numberBedroom: 0,
	elecSupplier: 0,
	elecMeterStart: 0,
	elecMeterEnd: 0,
	gasSupplier: 0,
	gasMeterStart: 0,
	gasMeterEnd: 0,
	waterSupplier: 0,
	waterMeterStart: 0,
	waterMeterEnd: 0,
	generatorVolumeUsed: 0,
	vehiculeId: 0,
	numberOfVehicule: 0,
	fromAddressId: 0,
	toAddressId: 0,
	kgCardboardWaste: 0,
	kgPaperWaste: 0,
	kgPlasticWaste: 0,
	kgFoodWaste: 0,
	kgGeneralWaste: 0,
	kgPaintWaste: 0,
	kgWoodWaste: 0,
	kgSteelWaste: 0,
	unitBatterieWaste: 0,
	amountVat: 0,
	amountTotal: 0,
	locationId: 0,
	carbonId: 0,
	paperPrinted: 0,
	sortedWaste: true,
	recycledPaper: false,
	greenEnergySupplier: false,
	consumptionElectricity: 0,
	consumptionGas: 0,
	consumptionWater: 0
};

/**
 * Item as exepnse, po, carbon
 */
export const FLAG_ENTITY_ITEM_TYPE = {
	expense: 0,
	po: 1,
	carbon: 2
};

export const PROJECT_CARBON_FIELD = `
  id
  createdAt
  updatedAt
  projectId
  entityId
  templateId
  templateFound
  description
  amount
  entityType
  strDate
  endDate
  coTwoCategory
  type
  subType
  additionalSubType
  paidByProduction
  verified
  km
  liter
  mcube
  kwh
  msquare
  number
  waterVolumeUsed
  supplierId
  userId
  kgCoTwo
  boolValue
  mealRed
  mealWhite
  mealVege
  mealNotSpecified
  numberOfNight
  numberBedroom
  elecSupplier
  elecMeterStart
  elecMeterEnd
  gasSupplier
  gasMeterStart
  gasMeterEnd
  waterSupplier
  waterMeterStart
  waterMeterEnd
  generatorVolumeUsed
  generatorFuelType
  reason
  vehiculeId
  numberOfVehicule
  fromAddressId
  toAddressId
  toAddress
  fromAddress
  kgCardboardWaste
  kgPaperWaste
  kgPlasticWaste
  kgFoodWaste
  kgGeneralWaste
  kgPaintWaste
  kgWoodWaste
  kgSteelWaste
  unitBatterieWaste
  userProjectId
  updated
  rawText
  locationId
  jsonData
  costCenter
  paperPrinted
  sortedWaste
  recycledPaper
  greenEnergySupplier
  consumptionElectricity
  consumptionGas
  consumptionWater
  supplier {
    id
    name
  }
  user {
    id
    name
    firstName
  }
  department {
    id
    value
    message
  }
  function {
    id
    value
    message
  }
  carbonValues {
    id
    createdAt
    updatedAt
    carbonId
    carbonModel
    kgCoTwo
  }
  carbonTemplate {
    id
    name
    position
  }
`;

export const OODRIVE_VALID_DOCUMENT_EXT = ['pdf', 'doc', 'docx'];

export const EXT_TO_ACCEPT = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'md', 'txt'];

export const DOCUMENT_VALIDATION_STEP = {
	step1: 1,
	step2: 2,
	step3: 3
};

export const DOCUMENT_VALIDATION_LEVEL = {
	gateKeeper: 5,
	productionAccountant: 6,
	upm: 7,
	lineProducer: 8,
	studio: 9
};

export const TYPE_ASK_TO_DELETE = {
	DEFAULT_ASK: -1,
	FOR_MANAGER: 0,
	FOR_LVL_ONE: 1
};

export const ROW_ASK_TO_DELETE = {
	DEFAULT_VALUE: -1,
	VALIDATED_MANAGER: 0,
	VALIDATED_LVL_ONE: 1,
	REJECTED_LVL_ONE: 4,
	REJECTED_LVL_TWO: 16
};

export const INTERNAL_STATUS = {
	DEFAULT_INTERNAL: 0,
	SENT_TO_PAY: 1
};

export const VALIDATION_TYPE = {
	NOT_SUBMITTED: 0,
	SUBMITTED: 1,
	VALIDATED_LEVEL_1: 2,
	REFUSED_LEVEL_1: 4,
	VALIDATED_LEVEL_2: 8,
	REFUSED_LEVEL_2: 16
};

export const COMPLETION_STATUS = {
	DEFAULT_VALUE: -1,
	MANAGER: 0,
	LVL_ONE: 1,
	LVL_TWO: 2,
	PAYROLL: 3
};

export const STATUS_VALIDATE_PLANNING = {
	LOCKED: -1,
	DEFAULT_STATUS: 0,
	INITIAL: 1,
	INPROGRESS: 2,
	FINAL: 3,
	CLOSED: 4,
	CLOSED_PAYROLL: 5
};

export const PLANNING_LEVEL = {
	MANAGER: 0,
	CHARG_PROD: 1,
	ADM_PROD: 2,
	PAI: 3
};

export const BREAKPOINTS = {
	MOBILE: 576, // For mobile devices
	TABLET: 768, // For tablets
	DESKTOP: 992, // For desktop
	LARGE_DESKTOP: 1200 // For large desktop
};

export const DEVICE = {
	WEB: 'web',
	IOS: 'ios',
	ANDROID: 'android'
};

export const TYPE_DELETED = {
	SIMPLE: 'simple',
	ASK: 'ask',
	UNDELED: 'undeled'
};

export const formatDateConverted = (dateString) => {
	if (dateString) {
		const typeDate = dateString.toString().trim().substring(0, 10);
		const [year, month, day] = typeDate.split('-');
		return `${day}/${month}/${year}`;
	}
	return null;
};

export const formatDatesInString = (inputString, format = 'YYYY-MM-DD') => {
	const dateRegex = /\b(\d{1,4})[-\/](\d{1,2})[-\/](\d{1,4})(T\d{2}:\d{2}:\d{2}Z)?\b/g;
	if (inputString) {
		const formattedString = inputString.replace(dateRegex, (match, p1, p2, p3) => {
			let day, month, year;
			if (p1.length === 4) {
				year = p1;
				month = p2;
				day = p3;
			} else if (p3.length === 4) {
				year = p3;
				month = p2;
				day = p1;
			} else {
				day = p1;
				month = p2;
				year = p3;
			}
			if (day > 31 || month > 12) {
				[day, month] = [month, day];
			}
			const parsedDate = new Date(Date.UTC(year, month - 1, day));

			if (isNaN(parsedDate)) {
				return match;
			}
			let formattedDate;
			switch (format) {
				case 'DD/MM/YYYY':
					formattedDate = `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;
					break;
				case 'MM/DD/YYYY':
					formattedDate = `${month.padStart(2, '0')}/${day.padStart(2, '0')}/${year}`;
					break;
				case 'YYYY/MM/DD':
					formattedDate = `${year}/${month.padStart(2, '0')}/${day.padStart(2, '0')}`;
					break;
				case 'DD-MM-YYYY':
					formattedDate = `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`;
					break;
				case 'MM-DD-YYYY':
					formattedDate = `${month.padStart(2, '0')}-${day.padStart(2, '0')}-${year}`;
					break;
				case 'YYYY-MM-DD':
					formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
					break;
				default:
					formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
			}

			return formattedDate;
		});

		return formattedString;
	}
	return '';
};

export const OPTION_CONTEXT_MENU = {
	SOLVE_UPDATE: 1,
	SEE_INFO: 2,
	COPY: 3,
	PASTE: 4,
	CLEAR_COPY: 5,
	TRACE: 6,
	UNTRACE: 7,
	REPLACE: 8,
	DELETE: 9,
	ASK_DELETE: 10,
	UNDELETE: 11,
	SEND_FOR_VALIDATION: 12,
	VALIDATE: 13,
	REJECT: 14,
	VALIDATE_ALL: 15,
	SEE_INFO_MULTIPLE: 16
};
/**
 * MP: Northern Mariana Islands
 * GU: Guam
 * AS: American Samoa
 * PR: Pureto Rico
 * CA: Canada
 * BS: Bahamas
 * BB: Barbados
 * AI: Anguilla
 * AG: Antigua and Barbuda
 * VG: British Virgin Islands
 * KY: Cayman Islands
 * BM: Bermuda
 * GD: Grenada
 * TC: Turks and Caicos Islands
 * JM: Jamaica
 * MS: Montserrat
 * SX: Sint Maarten
 * LC: Saint Lucia
 * DM: Dominica
 * VC: Saint Vincent and the Grenadines
 * DO: Dominican Republic
 * TT: Trinidad and Tobago
 * KN: Saint Kitts and Nevis
 * US: United states
 * @type {string[]}
 */
export const NANP_COUNTRY_CODES = [
	/*
	'MP',
	'GU',
	'AS',
	'PR',
	'CA',
	'BS',
	'BB',
	'AI',
	'AG',
	'VG',
	'KY',
	'BM',
	'GD',
	'TC',
	'JM',
	'MS',
	'SX',
	'LC',
	'DM',
	'VC',
	'DO',
	'TT',
	'KN',
	'US'
  */
];

export const REG_SEARCH_FORMAT = {
	pattern: /[\s"'\/\\]+/g
};

export const FIMALAC_PROJECT_FLAG = {
	SALARY: 0,
	PRIME: 1,
	DEFRAYAL: 2,
	GROSS: 3
};
export const FIMALAC_PROJECT_LABEL_FLAG = {
	SALARY: 'salary',
	PRIME: 'prime',
	DEFRAYAL: 'defrayal',
	GROSS: 'gross'
};
export const CELL = {
	WIDTH: 150
};

import gql from 'graphql-tag';
import { isNil } from '@/shared/utils';

//import * as Sentry from "@sentry/vue";
import * as Sentry from '@sentry/browser';

// https://vuejs.org/v2/guide/state-management.html
import { getTgsWebVersion } from '@/shared/version';

import { getAllFormsData, getAllLoginFormsData, addForm, addLabelToFormContentInDB, getAllMenus } from '@/cruds/language.crud';
import { myProject, setSlaveProjectStatus } from '@/cruds/project.crud';
import { myProfile } from '@/cruds/users.crud';
import * as _ from 'lodash';

const AUTH_TOKEN = 'jwt-token';
const AUTH_TOKEN_DATA = 'jwt-token_data';
const TOKEN_LINKED_TO_APP_VERSION = 'jwt-token-app-version';
const CURRENT_TIMESHEET = 'cur_ts';
const CURRENT_PROJECT = 'current-project';
const SAVED_NO_TRANSLATIONS = 'saved-no-trans-menus';
const CURRENT_DELIVERIES_DOC = 'current-doc-deliveries';
const JOB_REQUEST_LANG_ON_LOGGING = 'job-request-default-language-with-token';
const JOB_REQUEST_LANG_ON_LOGOUT = 'job-request-default-language-no-token';

export var store = {
	state: {
		debug: true,
		token: '',
		myProfile: {},
		myProject: {},
		noTranslationMenus: {},
		myContracts: []
	},
	async reloadMyProject() {
		await myProject().then((record) => {
			// console.log("in Onload myProject:", record);
			this.state.myProject = record;
			localStorage.setItem(CURRENT_PROJECT, JSON.stringify(record));
			// menus not to translate
			const noTranslation = {
				userName: record.userName,
				hodName: record.hodName,
				managerName: record.managerName
			};
			this.state.noTranslationMenus = noTranslation;
			localStorage.setItem(SAVED_NO_TRANSLATIONS, JSON.stringify(noTranslation));
		});
	},
	async setProjectAction(project) {
		// all actions to be performed on store when project changes
		const prof = await myProfile();
		this.state.myProfile = prof;
		//console.log("in SetProjectAction myProfile",prof);
	},
	getScreenFormsMap() {
		return this.state.formsMap;
	},
	setScreenFormsMap(formMap) {
		return (this.state.formsMap = formMap);
	},
	getAllMenuMap() {
		return this.state.menuMap;
	},
	setAllMenuMap(menuMap) {
		return (this.state.menuMap = menuMap);
	},
	getFormMessage(componentName, label) {
		return componentName + ' ' + label;
	},
	isTokenValid() {
		let ret = false;
		if (!isNil(this.state.token) && this.state.token.length > 0) {
			const str = this.state.token.split('.')[1];
			const strToStringBase64 = Buffer.from(str, 'base64').toString('binary');
			const tok = JSON.parse(strToStringBase64);
			const expDate = tok.ExpirationDate;
			const timeNow = Date.now() / 1000;
			const tokVer = localStorage.getItem(TOKEN_LINKED_TO_APP_VERSION);
			const curVer = getTgsWebVersion();
			if (tokVer === curVer && timeNow < expDate) {
				return true;
			}
		}
		return ret;
	},
	jwtData() {
		if (!isNil(this.state.token) && this.state.token.length > 0) {
			const str = this.state.token.split('.')[1];
			const strToStringBase64 = Buffer.from(str, 'base64').toString('binary');
			const tok = JSON.parse(strToStringBase64);

			return tok;
		}

		return {};
	},
	isProd() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.IsProd;
		if (ret) {
			return ret;
		}
		return false;
	},
	isAdmin() {
		//console.log("IN IS ADMIN, tokenData:", this.jwtData());
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.IsAdmin;
		if (ret) {
			return ret;
		}
		return false;
	},
	isDev() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.IsDev;
		if (ret) {
			return ret;
		}
		return false;
	},
	isTranslator() {
		if (!isNil(this.state.token) && this.state.token.length > 0) {
			const ret = this.jwtData().TokenData.IsTranslator;
			if (ret) {
				return ret;
			}
		}
		return false;
	},
	greenReporting() {
		if (!isNil(this.getCurrentProjectConfig().greenReporting)) {
			return this.getCurrentProjectConfig().greenReporting;
		} else {
			return 0;
		}
	},
	isCarbonClapReporting() {
		if (!isNil(this.getCurrentProjectConfig().greenReporting)) {
			return this.getCurrentProjectConfig().greenReporting === 3;
		} else {
			return false;
		}
	},
	isFilm() {
		let ret = 0;
		if (!isNil(this.jwtData().TokenData)) {
			ret = this.jwtData().TokenData.ProjectType;
		}

		if (ret === 0) {
			return true;
		}
		return false;
	},
	isPme() {
		let ret = 0;
		if (!isNil(this.jwtData().TokenData)) {
			ret = this.jwtData().TokenData.ProjectType;
		}
		if (ret === 1) {
			return true;
		}
		return false;
	},
	isAgency() {
		let ret = 0;
		if (!isNil(this.jwtData().TokenData)) {
			ret = this.jwtData().TokenData.ProjectType;
		}
		if (ret === 2) {
			return true;
		}
		return false;
	},
	isFilmSingleUserFree() {
		let ret = 0;
		if (!isNil(this.jwtData().TokenData)) {
			ret = this.jwtData().TokenData.ProjectType;
		}
		if (ret === 3) {
			return true;
		}
		return false;
	},
	isFilmSingleUser() {
		let ret = 0;
		if (!isNil(this.jwtData().TokenData)) {
			ret = this.jwtData().TokenData.ProjectType;
		}
		if (ret === 4) {
			return true;
		}
		return false;
	},
	appLanguage() {
		if (isNil(this.jwtData().TokenData)) return 0;
		const ret = this.jwtData().TokenData.Language;
		if (ret) {
			return ret;
		}
		return 0;
	},
	projectID() {
		if (!_.isNil(this.jwtData().TokenData)) {
			const ret = this.jwtData().TokenData.ProjectID;
			return ret;
		}
		return 0;
	},
	userID() {
		if (!_.isNil(this.jwtData().TokenData)) {
			const ret = this.jwtData().TokenData.UserID;
			return ret;
		}
		return 0;
	},
	setTokenAction(token) {
		this.state.token = token;
		// console.log({token});
		// store data in local storage
		localStorage.setItem(AUTH_TOKEN, token);
		const tokData = this.jwtData().TokenData;
		localStorage.setItem(AUTH_TOKEN_DATA, tokData);
		const curVersion = getTgsWebVersion();
		localStorage.setItem(TOKEN_LINKED_TO_APP_VERSION, curVersion);
		// store environment in sentry
		Sentry.setTag('TGS_version', curVersion);
		if (!isNil(tokData)) {
			Sentry.setContext('tokenData', tokData);
			const projId = tokData.ProjectID.toString();
			Sentry.setTag('TGS_project', projId);
			const userId = tokData.UserID.toString();
			Sentry.setTag('TGS_user', userId);
		}
		if (this.state.debug) {
			//console.log('setTokenAction token:', token);
			//console.log('setTokenAction tokenData', tokData);
		}
	},
	setCurTimesheet(ts) {
		//  console.log("setCurTimesheet:",ts);
		this.state.curTs = ts;
		//localStorage.setItem(CURRENT_TIMESHEET, ts.strDate);
	},
	getCurTimesheet() {
		//var ts= localStorage.getItem(CURRENT_TIMESHEET);
		const ts = this.state.curTs;
		//    console.log("getCurTimesheet:",ts);
		return ts;
	},
	//////////////// PAIMENT RELATED FUNCTIONS
	SetProjectPaiementStatus(ps) {
		//  console.log("setCurTimesheet:",ts);
		this.state.ProjectPaimentStatus = ts;
		//localStorage.setItem(CURRENT_TIMESHEET, ts.strDate);
	},
	GetProjectPaiementStatus() {
		if (isNil(this.state.ProjectPaimentStatus) || this.state.ProjectPaimentStatus == false) {
			return false;
		} else {
			return true;
		}
	},
	GetTaccountField(item) {
		return process.env.VUE_APP_BOB_50_TACCOUNT; //703000 for now
	},
	//////////////// END PAIMENT RELATED FUNCTIONS
	setCurrentUserDocumentTableIndex(index) {
		this.state.currentUserDocumentTableIndex = index;
	},
	getCurrentUserDocumentTableIndex() {
		let index = 0;
		if (this.state.currentUserDocumentTableIndex === undefined) {
		} else {
			index = this.state.currentUserDocumentTableIndex;
		}
		//    console.log("getCurTimesheet:",ts);
		return index;
	},
	setCurrentDocumentId(docId) {
		this.state.currentDocumentId = docId;
	},
	getCurrentDocumentId() {
		let id = 0;
		if (!isNil(this.state.currentDocumentId)) {
			id = this.state.currentDocumentId;
		}
		return id;
	},
	setCurrentDocument(doc) {
		this.state.currentDocument = doc;
	},
	getCurrentDocument() {
		let doc = {};
		if (!isNil(this.state.currentDocument)) {
			doc = this.state.currentDocument;
		}
		return doc;
	},
	setCurrentDocumentTableIndex(index) {
		this.state.currentDocumentTableIndex = index;
	},
	getCurrentDocumentTableIndex() {
		let index = 0;
		if (this.state.currentDocumentTableIndex === undefined) {
		} else {
			index = this.state.currentDocumentTableIndex;
		}
		return index;
	},
	getCurrentLicenseTableIndex() {
		let index = 0;
		if (this.state.currentAccomodationTableIndex === undefined) {
		} else {
			index = this.state.currentAccomodationTableIndex;
		}
		return index;
	},
	setCurrentLicenseTableIndex(index) {
		this.state.currentAccomodationTableIndex = index;
	},
	getCurrentAccomodationTableIndex() {
		let index = 0;
		if (this.state.currentAccomodationTableIndex === undefined) {
		} else {
			index = this.state.currentAccomodationTableIndex;
		}
		return index;
	},
	setCurrentAccomodationTableIndex(index) {
		this.state.currentAccomodationTableIndex = index;
	},
	getCurrentMyAccomodationTableIndex() {
		let index = 0;
		if (this.state.currentMyAccomodationTableIndex === undefined) {
		} else {
			index = this.state.currentMyAccomodationTableIndex;
		}
		return index;
	},
	setCurrentMyAccomodationTableIndex(index) {
		this.state.currentMyAccomodationTableIndex = index;
	},
	setCurExpense(exp) {
		this.state.curExp = exp;
	},
	getCurExpense() {
		const exp = this.state.curExp;
		return exp;
	},
	setCurPO(po) {
		this.state.curPO = po;
	},
	getCurPO() {
		const po = this.state.curPO;

		return po;
	},
	setCurBudgetDataForDepartment(BudgetDataItem) {
		this.state.getCurBudgetDataForDepartment = BudgetDataItem;
	},
	getCurBudgetDataForDepartment() {
		const BudgetDataItem = this.state.getCurBudgetDataForDepartment;

		return BudgetDataItem;
	},
	setCurBudgetDataForDepartmentCategory(BudgetDataItem) {
		this.state.getCurBudgetDataForDepartmentCategory = BudgetDataItem;
	},
	getCurBudgetDataForDepartmentCategory() {
		const BudgetDataItem = this.state.getCurBudgetDataForDepartmentCategory;

		return BudgetDataItem;
	},
	setCurDepartment(DepartmentItem) {
		this.state.getCurDepartment = DepartmentItem;
	},
	getCurDepartment() {
		const DepartmentItem = this.state.getCurDepartment;

		return DepartmentItem;
	},
	clearAllAction() {
		//if (this.state.debug) console.log('clearAll triggered')
		this.state.token = '';
		// clear localstorage
		localStorage.removeItem(AUTH_TOKEN);
		Sentry.setContext('tokenData', null);
	},
	parseFormScreensContent(data) {
		/* formMap object has the following structure:
    formMap {
      formName: string (key), objForm { formNumber: int, messageMap: map { labelID (int key), labelText} }
      }
    */
		let formMap = new Map();
		let curFormObj = {};
		for (let i = 0; i < data.length; i++) {
			curFormObj = formMap.get(data[i].formName);
			if (curFormObj == undefined) {
				// create the object for the form
				curFormObj = {};
				curFormObj.formNumber = parseInt(data[i].formNumber);
				curFormObj.messageMap = new Map();
				formMap.set(data[i].formName, curFormObj);
			}
			if (parseInt(data[i].value) != -1) {
				// if value == -1 message undefined, no message yet for this form
				curFormObj.messageMap.set(parseInt(data[i].value), data[i].message);
			}
		}
		//console.log("Loaded form data from db, formMap:", formMap);
		return formMap;
	},
	parseAllMenuContent(data) {
		/* parse database menuitems and fills a  map with menunumber and  an array menuitems :
    we create a map of menuNumber =>
    [{
        value: 0,
        text: 'Not defined'
    }, {};;;
  ]
    */
		let menuMap = new Map();
		let curMenuObj = {};
		for (let i = 0; i < data.length; i++) {
			curMenuObj = menuMap.get(parseInt(data[i].number));
			if (curMenuObj == undefined) {
				// create the object for the menu
				//console.log("in parseMenu, create curMenuObj for number:",parseInt(data[i].number));
				curMenuObj = {};
				curMenuObj.number = parseInt(data[i].number);
				curMenuObj.menuArray = [];
				menuMap.set(curMenuObj.number, curMenuObj.menuArray);
			}
			// adding item in curMenuObj
			let curMenuItem = {};
			curMenuItem.value = parseInt(data[i].value);
			curMenuItem.text = data[i].message;
			//console.log("in parseMenu, create curMenuItem for value:",curMenuItem.value);
			let ar = menuMap.get(parseInt(data[i].number));
			ar.push(curMenuItem);
		}
		//console.log("Loaded menu data from db, menuMap:", menuMap);
		return menuMap;
	},
	getToken() {
		return localStorage.getItem(AUTH_TOKEN);
	},
	getTokenData() {
		return localStorage.getItem(AUTH_TOKEN_DATA);
	},
	async onLoad() {
		try {
			this.state.token = localStorage.getItem(AUTH_TOKEN);
			let data = await getAllFormsData();
			const formsMap = this.parseFormScreensContent(data);
			this.setScreenFormsMap(formsMap);
			data = await getAllMenus();
			const menuMap = this.parseAllMenuContent(data);
			this.setAllMenuMap(menuMap);
			await myProject().then((record) => {
				if (record) {
					this.state.myProject = record;
					localStorage.setItem(CURRENT_PROJECT, JSON.stringify(record));
					const noTranslation = {
						userName: record.userName,
						hodName: record.hodName,
						managerName: record.managerName
					};
					this.state.noTranslationMenus = noTranslation;
					localStorage.setItem(SAVED_NO_TRANSLATIONS, JSON.stringify(noTranslation));
				}
			});

			return true;
		} catch (_) {
			return false;
		}
	},
	async onNewToken(apolloClient, token) {
		try {
			this.clearAllAction();
			//console.log("onNewToken:",token);
			if (typeof localStorage !== 'undefined' && token) {
				this.setTokenAction(token);
				//console.log("onNewToken:",token);
				await this.onLoad();
			}
			await apolloClient.resetStore(); // catch error à l'utilisation.

			return true;
		} catch (e) {}

		return false;
	},
	async onLogout(apolloClient) {
		if (typeof localStorage !== 'undefined') {
			this.clearAllAction();
		}
		await apolloClient.resetStore();
	},
	getTimeZoneStr() {
		// getting timezone
		if (isNil(this.state.timeZoneStr)) {
			const d = new Date();
			const n = d.getTimezoneOffset();
			let hOffset, sign;
			if (n < 0) {
				hOffset = -n / 60;
				sign = '+';
			} else {
				hOffset = n / 60;
				sign = '-';
			}
			let BASE = '00';
			this.state.timeZoneStr = hOffset ? sign + BASE.substr(0, 2 - Math.ceil(hOffset / 10)) + hOffset : sign + BASE;
			this.state.timeZoneStr = this.state.timeZoneStr + ':00';
			this.state.timeZoneHourDif = hOffset;
		}
		return this.state.timeZoneStr;
	},
	getTimeZoneHourDif() {
		// getting timezone
		this.getTimeZoneStr();
		return this.state.timeZoneHourDif;
	},
	canAssign() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.CanAssign;
		if (ret) {
			return ret;
		}
		return false;
	},
	canAccessUsers() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.CanAccessUsers;
		if (ret) {
			return ret;
		}
		return false;
	},
	canAccessBudget() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.CanAccessBudget;
		if (ret) {
			return ret;
		}
		return false;
	},
	canAccessGreenReporting() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.CanAccessGreenReporting;
		if (ret) {
			return ret;
		}
		return false;
	},
	canAccessConfig() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.CanAccessConfig;
		if (ret) {
			return ret;
		}
		return false;
	},
	canAccessMyBudget() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.CanAccessMyBudget;
		if (ret) {
			return ret;
		}
		return false;
	},
	canAccessOnboarding() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.CanAccessOnboarding;
		if (ret) {
			return ret;
		}
		return false;
	},
	userProjectID() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = parseInt(this.jwtData().TokenData.UserProjectId);
		if (ret) {
			return ret;
		}
		return 0;
	},
	canEditExpense() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.CanEditExpense;
		if (ret) {
			return ret;
		}
		return 0;
	},
	canEditPo() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.CanEditPo;
		if (ret) {
			return ret;
		}
		return 0;
	},
	canLockBudget() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.CanLockBudget;
		if (ret) {
			return ret;
		}
		return 0;
	},
	canModifyEstimatedFinalCost() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.CanModifyEstimatedFinalCost;
		if (ret) {
			return ret;
		}
		return 0;
	},
	canEditAndAddCode() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.CanEditAndAddCode;
		if (ret) {
			return ret;
		}
		return 0;
	},
	canAccessTimesheetEntryForCrew() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.CanAccessTimesheetEntryForCrew;
		if (ret) {
			return ret;
		}
		return false;
	},
	canAccessExpenseEntryForCrew() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.CanAccessExpenseEntryForCrew;
		if (ret) {
			return ret;
		}
		return false;
	},
	canAccessPoEntryForCrew() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.CanAccessPoEntryForCrew;
		if (ret) {
			return ret;
		}
		return false;
	},
	canManageDocuments() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.CanManageDocuments;
		if (ret) {
			return ret;
		}
		return false;
	},
	canManageDocumentPackage() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.CanManageDocumentPackage;
		if (ret) {
			return ret;
		}
		return false;
	},
	canManageCallSheets() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.CanManageCallSheets;
		if (ret) {
			return ret;
		}
		return false;
	},
	canViewConfigurationScreen() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.CanViewConfigurationScreen;
		if (ret) {
			return ret;
		}
		return false;
	},
	canManageAccommodations() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.CanManageAccommodations;
		if (ret) {
			return ret;
		}
		return false;
	},
	canManageLocations() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.CanManageLocations;
		if (ret) {
			return ret;
		}
		return false;
	},
	canViewReportingScreens() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.CanViewReportingScreens;
		if (ret) {
			return ret;
		}
		return false;
	},
	canExportImport() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.CanExportImport;
		if (ret) {
			return ret;
		}
		return false;
	},
	canManageCarbonRemoval() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.CanManageCarbonRemoval;
		if (ret) {
			return ret;
		}
		return false;
	},
	canManageTipOfTheDay() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.CanManageTipOfTheDay;
		if (ret) {
			return ret;
		}
		return false;
	},
	canViewDepartmentBudget() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.CanViewDepartmentBudget;
		if (ret) {
			return ret;
		}
		return false;
	},
	canManageCrewList() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.CanManageCrewList;
		if (ret) {
			return ret;
		}
		return false;
	},
	canViewGlobalBudget() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.CanViewGlobalBudget;
		if (ret) {
			return ret;
		}
		return false;
	},
	canManageTransport() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.CanManageTransport;
		if (ret) {
			return ret;
		}
		return false;
	},
	canViewGreenTable() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.CanViewGreenTable;
		if (ret) {
			return ret;
		}
		return false;
	},
	canViewWasteScreen() {
		if (isNil(this.jwtData().TokenData)) return false;
		const ret = this.jwtData().TokenData.CanViewWasteScreens;
		if (ret) {
			return ret;
		}
		return false;
	},
	getCurrentProjectConfig() {
		return JSON.parse(localStorage.getItem(CURRENT_PROJECT));
	},
	getSavedNoTranslationMenus() {
		return JSON.parse(localStorage.getItem(SAVED_NO_TRANSLATIONS));
	}
};

<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		v-model="isVisible"
		:ok-only="okOnly"
		:size="size"
		:class="customClass"
		:title="title"
		ok-variant="success"
		@ok="handleModalOk"
		@close="handleModalClose"
		@hide="handleModalHide"
		modal-class="mui-animation"
		:fade="false"
	>
		<div :key="contentRefreshToken" class="content">
			<div v-if="$hasSlot('header')" class="base-modal-header">
				<slot name="header" />
			</div>
			<div class="base-modal-body">
				<slot />
			</div>
			<div v-if="$hasSlot('footer')" class="base-modal-footer">
				<slot name="footer" />
			</div>
		</div>
	</b-modal>
</template>

<script>
import mapProps from '@/shared/vuePropsMapper';

export default {
	name: 'BaseModalComponent',
	props: {
		...mapProps(['visible', 'okOnly'], {
			type: Boolean,
			required: false,
			default: false
		}),
		title: {
			type: String,
			required: false,
			default: null
		},
		customClass: {
			type: String,
			required: false,
			default: ''
		},
		size: {
			type: String,
			required: false,
			default: 'sm',
			validator: (v) => ['xl', 'sm', 'lg'].includes(v)
		}
	},
	data() {
		return {
			contentRefreshToken: 0
		};
	},
	computed: {
		isVisible: {
			get() {
				return this.visible;
			},
			/**
			 * @param {Boolean} status
			 */
			set(status) {
				this.$emit('update:visible', status);
			}
		}
	},
	methods: {
		handleModalOk($event) {
			this.$emit('ok', $event);
		},
		handleModalClose($event) {
			this.$emit('close', $event);
		},
		handleModalHide($event) {
			this.isVisible = false;
			this.contentRefreshToken = this.contentRefreshToken + 1;
			this.$emit('hide', $event);
		}
	}
};
</script>

const state = {
	navs: []
};

const getters = {
	getNavItems: (state) => state.navs
};

const mutations = {
	newStateOfNavItems(state, payload) {
		if (payload.isUpdate) {
			state.navs.forEach((value, index) => {
				if (index === +payload.index) {
					value.open = !value.open;
				}
			});
		} else {
			state.navs = payload.navs;
		}
	}
};

const actions = {
	mutateNavItems({ commit }, payload) {
		commit('newStateOfNavItems', payload);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'core-js/es6/promise';
import 'core-js/es6/string';
import 'core-js/es7/array';
// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue';
import { App as CapacitorApp } from '@capacitor/app';
import App from './App';
import router from './router';
import { createProvider } from './vue-apollo';
import { store as initStore } from '@/store';
import store from '@/store/store';

import { defineCustomElements } from '@ionic/pwa-elements/loader';

import ApiService from './services/api.service';
import StorageService from '@/services/storage.service';
import '@/services/context.service';
import '@/services/components.service';
import '@/services/filters.service';
import '@/services/momentFilter.service';
import '@/services/directives.service';
import '@/directives/mask-time';

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

// todo
// cssVars()

// https://github.com/vuejs/vue-cli/issues/3407#issuecomment-459985313
// Pour npm run build, problem with terser

//Vue.use(require("moment"));

//Vue.component('date-picker', DatePicker)

//import languageMessages from "@/mixins/languageMessages";
//Vue.mixin(languageMessages);
//Vue.mixin([globalMixin, languageMessages]);

if (process.env.NODE_ENV !== 'development') {
	Sentry.init({
		dsn: process.env.VUE_APP_SENTRY_DSN,
		integrations: [new Integrations.Vue({ Vue, attachProps: true })]
	});
}
// console.log("VUE_APP_GQL")
// console.log('##############')
// console.log('ENV: ', process.env.NODE_ENV)
// console.log('##############')
ApiService.init(process.env.VUE_APP_GQL);

Vue.prototype.$axios = ApiService;
Vue.prototype.$storage = StorageService;

import '@/plugins/bootstrap-vue';
import '@/plugins/event-bus';
import '@/plugins/device';
import '@/plugins/vue-screen';
import '@/plugins/toast';
import '@/plugins/v-select';
import '@/plugins/v-calendar';
import '@/plugins/v-viewer';
import '@/plugins/vue-tour';
import '@/plugins/vue-tagsinput';
import './registerServiceWorker';
import '@/plugins/vue-cookies';
import '@/plugins/vue-treeview';
import '@/plugins/baseMixin';
import '@/plugins/v-lazyload';
import '@/plugins/bootstrap-icon';
import '@/plugins/vue-easy-dnd';
import '@/plugins/vue-loading-overlay';
import '@/plugins/pwa-status-bar-plugin';
import '@/plugins/vue-mask';
import '@/plugins/vue2-google-maps';
import '@/plugins/vue-prime';
import '@/plugins/vue-cropper';

import NProgress from 'vue-nprogress';

import Tooltip from 'primevue/tooltip';

Vue.directive('tooltip', Tooltip);
Vue.use(NProgress);
Vue.config.productionTip = false;

const initHeadScript = () => {
	const script = document.createElement('script');
	const key = process.env.VUE_APP_GOOLGE_API_KEY;
	const options = [`key=${key}`, 'libraries=places'];
	script.setAttribute('src', `https://maps.googleapis.com/maps/api/js?${options.join('&')}`);
	document.head.appendChild(script);
};

/* eslint-disable no-new */
ApiService.mount401Interceptor();
const nprogress = new NProgress();

CapacitorApp.addListener('backButton', ({ canGoBack }) => {
	if (!canGoBack) {
		CapacitorApp.exitApp();
	} else {
		window.history.back();
	}
});

const app = new Vue({
	el: '#app',
	router,
	store,
	nprogress,
	components: {
		App
	},
	async beforeCreate() {
		const storedProjectConfig = initStore.getCurrentProjectConfig();
		if ((_.isNil(initStore.state.myProject) || JSON.stringify(initStore.state.myProject) === '{}') && !_.isNil(storedProjectConfig))
			initStore.state.myProject = storedProjectConfig;
	},
	async created() {
		this.$nextTick(async () => {
			await initStore.onLoad();
		});
	},
	mounted() {
		ApiService.setTokenHeader();
		// REFRESH TOKEN
		if (!window.isGoogleMapsApiLoaded) initHeadScript();
	},
	template: '<App/>',
	apolloProvider: createProvider({
		router,
		store
	})
});

store.$app = app;
defineCustomElements(window);

import Vue from 'vue';
import VueApollo from 'vue-apollo';
// import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client'

import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink, from } from 'apollo-link';
import { onError } from 'apollo-link-error';

// import { createApolloFetch } from 'apollo-fetch'
// https://github.com/apollographql/apollo-fetch

import { store } from '@/store';
import { version } from './../package.json';

// Install the vue plugin
Vue.use(VueApollo);

export const BASE_URL = process.env.BASE_URL;
const uri = `${process.env.VUE_APP_GQL}/graphql`;
const httpLink = createHttpLink({ uri });
let defaultRouter = null;
let defaultStore = null;

const acceptJsonMiddleware = new ApolloLink((operation, forward) => {
	// get the authentication token from local storage if it exists
	const { token } = store.state || {};

	operation.setContext(({ headers = {} }) => ({
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : '',
			'X-Client-Version': version,
			accept: 'application/json'
		}
	}));

	return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
	// console.error({ graphQLErrors, networkError });
	if (networkError && networkError.statusCode === 505) {
		defaultStore.dispatch('header/setErrorAppVersion', networkError.message ? networkError.message.trim() : 'Error: App Version');
		store.onLogout();
		defaultRouter.push('/pages/login').catch(() => {});
	} else if (networkError && networkError.statusCode === 511) {
		defaultStore.dispatch('header/setEmptyTokenData', true);
	}
});

// Create apollo client
export const apolloClient = new ApolloClient({
	link: from([acceptJsonMiddleware, errorLink, httpLink]),
	cache: new InMemoryCache({
		dataIdFromObject: (obj) => {
			switch (obj.__typename) {
				case 'Expense':
					return `Expense:${obj.id}.${obj.userProjectId}.${obj.encoderId}`;
				// Add cases for other types as needed
				default:
					return obj.id ? `${obj.__typename}:${obj.id}` : null; // Fallback to default handling
			}
		}
	})
});

/**
 * Call this in the Vue app file
 *
 * @param {Object} [options={}]
 * @return {Function}
 */
export function createProvider(options = {}) {
	defaultRouter = options.router;
	defaultStore = options.store;

	// Create vue apollo provider
	const apolloProvider = new VueApollo({
		defaultClient: apolloClient
	});

	return apolloProvider;
}
